.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #3498db;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-select__control {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 13px 15px;
}
.react-select__single-value {
  font-size: 16px;
  line-height: 20px;
  color: #121111;
}
.react-select__value-container {
  line-height: 20px;
}
.react-select__menu {
  background-color: #fff;
  padding: 12px;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.04);
  border-radius: 0 0 8px 8px;
}
.react-select__menu .react-select__option {
  margin-bottom: 6px;
  cursor: pointer;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}
